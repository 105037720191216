// material-ui
import {
  Box,
  Grid,
  FormHelperText,
  IconButton,
  Button,
  Stack,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  // Snackbar
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import AnimateButton from "components/@extended/AnimateButton";

import strapiServicesInstance from "store/strapiServices";
import { useEffect, useState } from "react";
import strapiServices from "../../store/strapiServices";
import services from "../../store/services";
import { useDispatch } from "react-redux";
import { activeItem } from "store/reducers/menu";
import AnalyticEcommerce from "components/cards/statistics/AnalyticEcommerce";
import userStrapiServices from "../../store/userStrapiServices";
// import { Alert } from '@mui/material';
const baseUrl = process.env.REACT_APP_API_KEY; //.env eklenemedi
// const baseUrl = 'http://213.136.91.105:1337/api/';
// ============================|| FIREBASE - REGISTER ||============================ //
// !!!!!profile sadece alt bayi görüyor!!!!!
const Profile = () => {
  const dispatch = useDispatch();
  const role = JSON.parse(localStorage.getItem("role"));
  const user = JSON.parse(localStorage.getItem("user"));
  const [items, setItems] = useState([
    {
      title: "Bayi Kazancı",
      count: 0,
      targetCount: 44236,
      percentage: 0,
      targetPercentage: 59.3,
      extra: 0,
      targetExtra: 35000,
      color: "success",
      role: [3, 4],
    },
    {
      title: "Oyuncu Sayısı",
      count: 0,
      targetCount: 78250,
      percentage: 0,
      targetPercentage: 70.5,
      extra: 0,
      targetExtra: 8900,
      color: "primary",
      role: [3, 4],
    },
    {
      title: "Günlük Kazanç",
      count: 0,
      targetCount: 18800,
      percentage: 0,
      targetPercentage: 27.4,
      extra: 0,
      targetExtra: 1943,
      color: "warning",
      role: [3, 4],
    },
  ]);
  const [control, setControl] = useState(false);
  const [id, setId] = useState(0);
  const [dataInit, setDataInit] = useState({
    username: "",
    email: "",
    password: "",
    permission: 3,
    blocked: true,
    vault: 0,
    submit: null,
  });
  const [totalMano, settotalMano] = useState(0);
  const [totalMainMano, setMotalMainMano] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);

  const fetchData = async () => {
    let query = "";
    if (role.toString() !== "4") {
      //ADMİN kullanıcısı değilse sadece kendisinin verisini görmesi için
      query = `filters[dealer]=${user.user.id}&`;
    }
    //bu AY İÇİN
    const d = new Date();
    const startOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
    const endOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    //Bu AY İÇİN
    //Bugün İÇİN
    d.setHours(0, 0, 0, 0); // Günün başlangıcı (00:00:00)
    const tomorrow = new Date(d); // Yarının tarihi
    tomorrow.setDate(d.getDate() + 1); // Yarının tarihi (bugünün üzerine bir gün ekler)
    // Bugünün başlangıç ve bitiş saatlerini ISO formatına çevir
    const startOfDay = d.toISOString();
    const endOfDay = tomorrow.toISOString();
    //Bugün İÇİN
    const queryDateDay = `filters[createdAt][$gte]=${startOfDay}&filters[createdAt][$lte]=${endOfDay}&`;
    const responseDay = await strapiServices.get(
      `/user-vault-logs?${query}${queryDateDay}populate=role,vault,dealer`
    );
    if (responseDay.data) {
      console.log(responseDay.data);
      // console.log(response.username);
      let valtotalEarning = 0;
      responseDay.data.forEach((item) => {
        valtotalEarning += item.attributes.dealer_mano || 0;
      });

      setTotalEarning(valtotalEarning);
    }

    const queryDate = `filters[createdAt][$gte]=${startOfMonth.toISOString()}&filters[createdAt][$lte]=${endOfMonth.toISOString()}&`;
    const response = await strapiServices.get(
      `/user-vault-logs?${query}${queryDate}populate=role,vault,dealer`
    );
    if (response.data) {
      let valtotalMano = 0;
      let valtotalMainMano = 0;
      response.data.forEach((item) => {
        valtotalMano += item.attributes.dealer_mano || 0; // değeri undefined ise veya yoksa 0 olarak kabul edilir
        valtotalMainMano += item.attributes.main_dealer_mano || 0;
      });
      settotalMano(valtotalMano);
      setMotalMainMano(valtotalMainMano);
    }

    const responseUser = await userStrapiServices.get(
      `/users?${query}${queryDate}`
    );
    setUserCount(responseUser.length);
    console.log(responseUser);
  };
  useEffect(() => {
    const intervals = items.map((item, index) => {
      const value =
        item.title === "Bayi Kazancı"
          ? totalMainMano
          : item.title === "Günlük Kazanç"
          ? totalEarning
          : item.title === "Alt Bayi Kazançları"
          ? totalMano
          : userCount;
      return setInterval(() => {
        setItems((prevItems) => {
          const newItems = [...prevItems];
          if (newItems[index].count < value) {
            newItems[index].count += 1;
          }
          if (newItems[index].percentage < item.targetPercentage) {
            newItems[index].percentage += 0.15;
          }
          if (newItems[index].extra < value) {
            newItems[index].extra += 1;
          }
          return newItems;
        });
      }, 100); // Değerlerin hızlıca artması için daha küçük bir zaman aralığı (örneğin 100ms) kullanabilirsiniz
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [totalMano, totalMainMano, userCount, totalEarning]);
  const getUser = async (id) => {
    const response = await strapiServices.get(
      `/users/${id}?populate=role,vault`
    );
    if (response) {
      console.log(response);
      // console.log(response.username);
      setDataInit({
        username: response.username,
        email: response.email,
        password: response.password,
        permission: response.role.id,
        vault: response.vault ? response.vault.vault : 0,
        blocked: !response.blocked, //switch de açık olan kısım aktif olsun diye
      });
    }
    fetchData();
  };
  const getMe = async () => {
    const response = await services.getMe();
    console.log(response);
    setId(response.id);
    getUser(response.id);
  };
  const vaultApi = async (type, data, dataId) => {
    if (type === "put") {
      await strapiServicesInstance
        .put(`/vaults/${dataId}`, data)
        .then((response2) => {
          console.log("response appService");
          console.log(response2);
          if (!response2) {
            return false;
          } else {
            return true;
          }
        });
    } else {
      await strapiServicesInstance.post(`/vaults`, data).then((response2) => {
        // console.log("response appService");
        // console.log(response);
        if (!response2) {
          return false;
        } else {
          return true;
        }
      });
    }
  };
  useEffect(() => {
    // if (getQueryParam("id")) {
    //   console.log("güncelleme ekranı");
    //   getUser(getQueryParam("id"));
    //   setId(getQueryParam("id"));
    // }
    getMe();
    dispatch(activeItem({ openItem: ["profile"] }));
  }, []);

  return (
    <Box sx={{ backgroundColor: "#fff", padding: "20px" }}>
      <Grid
        container
        rowSpacing={4.5}
        columnSpacing={2.75}
        style={{ marginBottom: "20px" }}
      >
        {items.map((item, index) =>
          item.role.includes(role) ? (
            <Grid key={index} item xs={12} sm={4} md={4} lg={4}>
              <AnalyticEcommerce
                title={item.title}
                count={item.count.toLocaleString()} // Virgülle ayrılmış sayı formatı için
                percentage={item.percentage.toFixed(1)}
                extra={item.extra.toLocaleString()} // Virgülle ayrılmış sayı formatı için
                color={item.color}
              />
            </Grid>
          ) : (
            ""
          )
        )}
      </Grid>
      <Formik
        initialValues={{
          username: dataInit.username,
          email: dataInit.email,
          password: id ? "*******" : "",
          permission: 3,
          blocked: dataInit.blocked,
          vault: dataInit.vault,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
          username: Yup.string().max(255).required("Bayi adı is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (id) {
              const data = {
                username: values.username,
                email: values.email,
                role: values.permission,
                blocked: !values.blocked,
                confirmed: true,
              };
              if (values.password !== "*******") {
                data.password = values.password;
              }
              await strapiServicesInstance
                .put(`/users/${id}`, data)
                .then(async (response) => {
                  // console.log("response appService");
                  // console.log(response);
                  if (!response) {
                    setStatus({ success: false });
                    setErrors({
                      submit: "Giriş yapılamadı. Bilgilerinizi kontrol ediniz.",
                    });
                  } else {
                    const dataVault = {
                      data: {
                        vault: values.vault,
                        users_permissions_user: response.id,
                      },
                    };
                    if (response.vault) {
                      if (!vaultApi("put", dataVault, response.vault.id)) {
                        setStatus({ success: false });
                        setErrors({
                          submit: "Güncellenemedi. Bir hata oluştu.",
                        });
                      } else {
                        setStatus({ success: true });
                        setSubmitting(true);
                        window.location.href = "profile";
                      }
                    } else {
                      if (!vaultApi("post", dataVault)) {
                        setStatus({ success: false });
                        setErrors({
                          submit: "Kayıt edilemedi. Bir hata oluştu.",
                        });
                      } else {
                        setStatus({ success: true });
                        setSubmitting(true);
                        window.location.href = "profile";
                      }
                    }
                  }
                });
            } else {
              const data = {
                username: values.username,
                email: values.email,
                password: values.password,
                role: values.permission,
                blocked: !values.blocked,
                confirmed: true,
              };
              await strapiServicesInstance
                .post("/users", data)
                .then(async (response) => {
                  // console.log("response appService");
                  // console.log(response);
                  if (!response) {
                    setStatus({ success: false });
                    setErrors({
                      submit: "Giriş yapılamadı. Bilgilerinizi kontrol ediniz.",
                    });
                  } else {
                    const dataVault = {
                      data: {
                        vault: values.vault,
                        users_permissions_user: response.id,
                      },
                    };
                    if (!vaultApi("post", dataVault)) {
                      setStatus({ success: false });
                      setErrors({
                        submit: "Kayıt edilemedi. Bir hata oluştu.",
                      });
                    } else {
                      setStatus({ success: true });
                      setSubmitting(true);
                      window.location.href = "/profile";
                    }
                  }
                });
            }
          } catch (err) {
            setStatus({ success: false });
            if (err.response && err.response.status === 400) {
              setErrors({
                submit:
                  "Invalid email or password. Please check your credentials and try again.",
              });
            } else {
              setErrors({
                submit: "An error occurred. Please try again later.",
              });
            }
            setSubmitting(false);
          }
        }}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login">Bayi Adı</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    value={values.username}
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter username"
                    fullWidth
                    error={Boolean(touched.username && errors.username)}
                  />
                  {touched.username && errors.username && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.username}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login">Email Address</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter email address"
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type="text"
                    value={values.password}
                    name="password"
                    // onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={() => {
                      if (values.password === "*******") {
                        handleChange("password")("");
                      }
                    }}
                    onBlur={() => {
                      if (values.password === "") {
                        handleChange("password")("*******");
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          size="large"
                        ></IconButton>
                      </InputAdornment>
                    }
                    placeholder="Enter password"
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel id="demo-simple-select-label">
                    Yetki Durumu
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.permission}
                    label="Permission"
                    onChange={(e) => {
                      handleChange("permission")(e);
                    }}
                  >
                    <MenuItem value={3}>Bayi</MenuItem>
                    <MenuItem value={4}>Admin Bayi</MenuItem>
                  </Select>
                </Stack>
              </Grid> */}

              <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login">Kasa</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="number"
                    value={values.vault}
                    name="vault"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    placeholder="Enter kasa"
                    fullWidth
                    disabled
                    error={Boolean(touched.vault && errors.vault)}
                  />
                  {touched.vault && errors.vault && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.vault}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  mt: -1,
                  textAlign: "right",
                  alignItems: "end",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.blocked}
                      // onChange={(e) => {
                      //   handleChange("blocked")(e);
                      // }}
                      name="blocked"
                      color="primary"
                      sx={{ marginRight: 0 }}
                      disabled
                    />
                  }
                  label={!values.blocked ? "Bloklandı" : "Aktif"}
                />
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className="button-pk"
                  >
                    {!id ? "Kaydet" : "Güncelle"}
                  </Button>
                </AnimateButton>
              </Grid>
              {/* <Grid item xs={12}>
                <Divider>
                  <Typography variant="caption"> Login with</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <FirebaseSocial />
              </Grid> */}
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Profile;
