import axios from "axios";
import { postVaultData } from "./service.js";

const API_URL = process.env.REACT_APP_API_KEY_USER;
const token = process.env.REACT_APP_API_TABLE_TOKEN;

class UserStrapiServices {
  async put(url, data, auth = true) {
    let headers = null;
    if (auth) {
      headers = {
        Authorization: "Bearer " + token,
      };
    }
    try {
      const response = await axios.put(API_URL + url, data, {
        headers: headers,
      });
      if (response.status === 200 && response.data.data) {
        postVaultData(response.data.data.attributes.vault, data.data.uid);
      }
      return response.data;
    } catch (e) {
      return false;
    }
  }
  async post(url, data, auth = true) {
    console.log("response");

    let headers = null;
    if (auth) {
      headers = {
        Authorization: "Bearer " + token,
      };
    }
    try {
      const response = await axios.post(API_URL + url, data, {
        headers: headers,
      });
      if (response.data && response.data.data && response.data.data.id) {
        postVaultData(response.data.data.attributes.vault, data.data.uid);
      }
      return response.data;
    } catch (e) {
      return false;
    }
  }

  async get(url, auth = true) {
    let headers = null;
    if (auth) {
      headers = {
        Authorization: "Bearer " + token,
      };
    }
    const response = await axios.get(API_URL + url, {
      headers: headers,
    });
    return response.data;
  }
}
const userStrapiServicesInstance = new UserStrapiServices();
export default userStrapiServicesInstance;
