import { useRoutes } from "react-router-dom";

// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import services from "store/services";
import { useEffect, useState } from "react";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const [id, setId] = useState(0);
  const getMe = async () => {
    const response = await services.getMe();
    // console.log("role", response.role.id);
    setId(response.role.id);

    // console.log(MainRoutes);
    // console.log(LoginRoutes);
    // console.log(id);
    getQueryParam(response.role.id);
  };
  const getQueryParam = (id) => {
    // const queryParams = new URLSearchParams(window.location.search);
    // console.log(queryParams);
    // console.log(window.location.pathname);

    const route_ = window.location.pathname.substring(1);
    // console.log(route_);
    // if (route_ === "") {
    //   // mainPage(id);
    // } else {
    const route = MainRoutes.children.find((route) => route.path === route_);
    // console.log(route);
    // console.log(route.role);
    // console.log(id);
    if (!route && id === 3) {
      window.location.href = "profile";
    }
    if (route && !route.role.includes(id.toString())) {
      mainPage(id);
    }

    // }
  };
  const mainPage = (id) => {
    if (id === 3) {
      window.location.href = "profile";
    } else {
      window.location.href = "/";
    }
  };
  useEffect(() => {
    if (localStorage.getItem("user")) {
      getMe();
    }
  }, []);
  return useRoutes([MainRoutes, LoginRoutes]);
}
