// material-ui
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Grid,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  // Snackbar
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import AnimateButton from "components/@extended/AnimateButton";
import axios from "axios";
import { useEffect, useState } from "react";
import strapiServices from "./../../store/strapiServices";
import CreateIcon from "@mui/icons-material/Create";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Link } from "react-router-dom";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
// import { Alert } from '@mui/material';
const userBaseUrl = process.env.REACT_APP_API_KEY_USER;
const baseUrl = process.env.REACT_APP_API_KEY;
// const baseUrl = 'http://213.136.91.105:1337/api/';
// ============================|| FIREBASE - REGISTER ||============================ //
const columns = [
  { id: "id", label: "" },
  { id: "name", label: "Adı", minWidth: 170 },
  {
    id: "vault",
    label: "Kasa",
    minWidth: 150,
    align: "right",
  },
  {
    id: "status",
    label: "Durum",
    minWidth: 150,
    align: "right",
  },
  {
    id: "process",
    label: "İşlem",
    minWidth: 170,
    align: "right",
  },
];

function createData(name, status) {
  return { name, status };
}

// let rows = [];
const rows__ = [
  createData("test bayii", "aktif"),
  createData("test bayii", "aktif"),
];

const Users = () => {
  const [control, setControl] = useState(false);
  const [rows_, setRows_] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dealer, setDealer] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getDealers = async () => {
    const response = await strapiServices.get(
      `/users?pagination[page]=${page}&pagination[pageSize]=${rowsPerPage}&sort[0]=id:desc&populate=vault`
    );
    console.log("Response:", response);
    console.log("rows__:", rows__);
    if (response) {
      setRows([]);
      setRows_(response);
      setControl(true);
      response.map((item) => {
        // console.log("Item:", item);
        const updatedRows = {
          id: item.id,
          name: item.username,
          vault: item.vault ? item.vault.vault : "0",
          status: item.blocked ? "Bloklandı" : "Aktif",
        };
        // console.log("Rows:", updatedRows);
        setRows((prevRows) => [...prevRows, updatedRows]);
      });
    }
  };

  const getUsers = async () => {
    const response = await strapiServices.get(
      `/users?pagination[page]=${page}&pagination[pageSize]=${rowsPerPage}&sort[0]=id:desc&populate=vault`
    );
    console.log("Response:", response);
    console.log("rows__:", rows__);
    if (response) {
      setRows([]);
      setRows_(response);
      setControl(true);
      response.map((item) => {
        // console.log("Item:", item);
        const updatedRows = {
          id: item.id,
          name: item.username,
          vault: item.vault ? item.vault.vault : "0",
          status: item.blocked ? "Bloklandı" : "Aktif",
        };
        // console.log("Rows:", updatedRows);
        setRows((prevRows) => [...prevRows, updatedRows]);
      });
    }
  };

  useEffect(() => {
    getUsers();
  }, [control, page, rowsPerPage]);

  useEffect(() => {
    console.log("rows");
    console.log(rows);
  }, [rows]);

  return (
    <Box sx={{ backgroundColor: "#fff", padding: "20px" }}>
      {/* <Link to="/add-users">
        <Button variant="outlined" color="success" style={{ float: "right" }}>
          <AutoFixHighIcon /> YENİ KULLANICI EKLE
        </Button>
      </Link> */}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === "process") {
                          return (
                            <TableCell key={column.id} align="right">
                              <Link
                                to={`/dealer-user?id=${row.id}`}
                                style={{ color: "#faad14" }}
                              >
                                <IconButton
                                  color="secondary"
                                  aria-label="add an alarm"
                                >
                                  <ControlPointDuplicateIcon color="warning" />
                                </IconButton>
                                Kullanıcı Ekle
                              </Link>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id !== "id"
                                ? column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value
                                : "-"}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default Users;
