import axios from "axios";

const API_URL = process.env.REACT_APP_API_KEY_PLAYER;
const url = "vault/zygomorphicQuintessenceHyperboloid";
const token = process.env.REACT_APP_API_KEY_MAINGAME_TOKEN;

const postVaultData = async (vault, uID) => {
  let data = JSON.stringify({
    vault: vault,
    uID: uID,
  });
  console.log("postVaultData");
  console.log(data);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_URL}${url}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    console.log(JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { postVaultData };
