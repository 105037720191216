import strapiServices from "./strapiServices";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_KEY;
//const API_URL = 'http://167.86.82.91:1453/api';

class Services {
  setAuthToken(token) {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }
  async register(values) {
    try {
      const strapiRegister = await strapiServices.post(
        "/auth/local/register",
        {
          username: values.username,
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          password: values.password,
        },
        false
      );

      console.log("response strapi register");
      console.log(strapiRegister);

      if (strapiRegister.jwt) {
        this.setAuthToken(strapiRegister.jwt);
        await localStorage.setItem("user", JSON.stringify(strapiRegister));
        window.location.href = "/";
        return strapiRegister;
      }
    } catch (error) {
      console.error("Error during registration", error);
      throw error;
    }
  }
  async login(values) {
    console.log("login start");
    console.log(values);

    // strapiServices.post asenkron bir işlem olduğu için beklenmeli
    const strapiLogin = await strapiServices.post(
      "/auth/local?populate=role",
      {
        identifier: values.email,
        password: values.password,
      },
      false
    );

    console.log("response strapi login");
    console.log(strapiLogin);
    if (!strapiLogin) {
      return strapiLogin;
    }
    if (strapiLogin.jwt) {
      this.setAuthToken(strapiLogin.jwt);
      await localStorage.setItem("user", JSON.stringify(strapiLogin));
      await this.getMe();
      window.location.href = "/";
      return strapiLogin;
    }

    console.log("strapiLogin is not successful, trying with axios");

    // try {
    //   const axiosLogin = await axios.post(
    //     API_URL + "/auth/local?populate=role",
    //     {
    //       identifier: values.email,
    //       password: values.password,
    //     }
    //   );

    //   console.log("response axios login");
    //   console.log(axiosLogin);

    //   if (axiosLogin.data.jwt) {
    //     await localStorage.setItem("user", JSON.stringify(axiosLogin.data));

    //     // window.location.href = '/app'; // Yorum satırına aldım, çünkü bu satırı kullanabilirsiniz.
    //     return axiosLogin;
    //   }
    // } catch (error) {
    //   console.error("Error during axios login", error);
    // }
  }

  async getMe() {
    // try {
    const response = await strapiServices.get("/users/me?populate=role");
    await localStorage.setItem("role", JSON.stringify(response.role.id));
    // console.log("response getMe");
    // console.log(response);
    return response;
    // } catch (error) {
    //   console.error("Error during getMe", error);
    // }
  }
}

export default new Services();
