// types
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

/*
axios.interceptors.request.use(
    (config) => {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.jwt) {
        config.headers.Authorization = `Bearer ${user.jwt}`;
      } else {
        window.location.href = '/app/login';
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);
*/

// initial state
const initialState = {
  user: null,
  company: [],
  status: "idle",
  error: null,
};
// ==============================|| SLICE - MENU ||============================== //
export const getCompany = createAsyncThunk(
  "user/getCompany",
  async ({ userId }) => {
    // console.log(process.env.BASE_URL);
    const response = await axios.get(
      //todo:: login ardından companyid ile çekecek
      `http://213.136.91.105:1337/api/companies?filters[users]=${userId}`,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).jwt,
        },
      }
    );
    return response.data;
  }
);
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setuser(state, action) {
      state.user = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.company = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default user.reducer;

export const { setuser } = user.actions;
