// assets
import { ChromeOutlined, DashboardOutlined } from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  DashboardOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const ID = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user")).user.id
  : 0;
const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
      role: "4",
    },
    {
      id: "dealers",
      title: "Bayiler",
      type: "item",
      url: "/dealers",
      icon: icons.ChromeOutlined,
      role: "4",
    },
    {
      id: "profile",
      title: "Profil",
      type: "item",
      url: "/profile",
      icon: icons.ChromeOutlined,
      role: "3",
    },
    {
      id: "users",
      title: "Kullanıcılar",
      type: "item",
      url: "/dealer-user?id=" + ID,
      icon: icons.ChromeOutlined,
      role: "3",
    },
    {
      id: "users",
      title: "Kullanıcılar",
      type: "item",
      url: "/users",
      icon: icons.ChromeOutlined,
      role: "4",
    },
  ],
};

export default dashboard;
