import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import Users from "pages/extra-pages/Users";
import Profile from "pages/extra-pages/Profile";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - sample page
const SamplePage = Loadable(lazy(() => import("pages/extra-pages/SamplePage")));
const AddUser = Loadable(lazy(() => import("pages/extra-pages/AddUser")));
const VaultProc = Loadable(lazy(() => import("pages/extra-pages/VaultProc")));
const VaultProcPayment = Loadable(
  lazy(() => import("pages/extra-pages/VaultProcPayment"))
);
const VaultProcPlayers = Loadable(
  lazy(() => import("pages/extra-pages/VaultProcPlayer"))
);
const VaultProcPaymentPlayers = Loadable(
  lazy(() => import("pages/extra-pages/VaultProcPaymentPlayer"))
);
const AddUserDealer = Loadable(
  lazy(() => import("pages/extra-pages/AddUserDealer"))
);
const UserDealer = Loadable(lazy(() => import("pages/extra-pages/UserDealer")));

// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
      role: ["4"],
    },
    // {
    //   path: 'color',
    //   element: <Color />
    // },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    {
      path: "dealers",
      element: <SamplePage />,
      role: ["4"],
    },
    {
      path: "users",
      element: <Users />,
      role: ["4"],
    },
    {
      path: "add-dealers",
      element: <AddUser />,
      role: ["4"],
    },
    {
      path: "deposit-users",
      element: <VaultProc />,
      role: ["4"],
    },
    {
      path: "payment-users",
      element: <VaultProcPayment />,
      role: ["4"],
    },
    {
      path: "deposit-players",
      element: <VaultProcPlayers />,
      role: ["4"],
    },
    {
      path: "payment-players",
      element: <VaultProcPaymentPlayers />,
      role: ["4"],
    },
    {
      path: "profile",
      element: <Profile />,
      role: ["3"],
    },
    {
      path: "dealer-user",
      element: <UserDealer />,
      role: ["4", "3"],
    },
    {
      path: "add-users",
      element: <AddUserDealer />,
      role: ["4"],
    },
    // {
    //   path: 'shadow',
    //   element: <Shadow />
    // },
    // {
    //   path: 'typography',
    //   element: <Typography />
    // },
    // {
    //   path: 'icons/ant',
    //   element: <AntIcons />
    // }
  ],
};

export default MainRoutes;
